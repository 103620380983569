import React from "react";
import './Info.scss';
import CarouselInfo from './CarouselInfo';

import content from '../content.json';

const {  fotos } = content.components;

export default ({ content }) => {
    return(
        <div className="info">
            <div className="info__slider">
                <img className="info__slider-bg" src="./static/images/bg-info-slider.png"/>
                <CarouselInfo content={ fotos }/>
            </div>
            <div className="info__content">
                <h2 className="info__content-title">
                    <span>GNT</span> e <span>EDUCAFRO</span> prepararam um evento recheado de inspiração e cultura para os estudantes da organização.
                </h2>
                <p>
                Em 2022, no Mês da Consciência Negra, o GNT embarcou em um projeto de impacto real com a EDUCAFRO, organização pioneira na pauta de educação, emancipação social e equidade racial. Promovemos um encontro motivacional e inspiracional com o objetivo de reconhecer, destacar e reivindicar a importância da presença negra nos mais variados espaços da sociedade.
                </p>
                <p>
                O evento aconteceu no Museu de Arte no Rio e contou com a participação de Luana Xavier como Mestre de Cerimônias, Stephanie Ribeiro e Valéria Almeida, além de profissionais da Globo e da EDUCAFRO.
                </p>
                <p>
                Conheça mais sobre o evento abaixo.
                </p>

            </div>
        </div>
    );
}












