import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


import './Modalinfo.scss';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 574,
	bgcolor: '#1A4B22',
	color: '#FDF8E5',
	boxShadow: 24,
	lineHeight: 1.4,
	borderRadius: 3,
	p: 3,
};

function Modalinfo({ show, setHide, infoBio, idBio }) {


    return (
        <Modal
          open={show}
          onClose={setHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          	<Box sx={style}>
				<h2 className="modal__title">
					{(idBio === infoBio.id) ? infoBio.name : ''}

					<a style={{marginLeft: '10px'}} target="_blank" href={(idBio === infoBio.id) ? infoBio.instagramUrl : ''} onClick={setHide}>
						<img  src="./static/images/icons_instagram-fill.svg" alt="Instagram"/>
					</a>

					<img className="modal__close" onClick={setHide} src="./static/images/close.png"/>
				</h2>
				{/* <h3 className="fw-bold m-b-15 color-red-100">
					{(idBio === infoBio.id) ? infoBio.subTitle : ''}
				</h3> */}
				<p className="fw-light fs-16 m-b-15">
					{(idBio === infoBio.id) ? infoBio.bioText : ''}
				</p>
				<p className="fw-light fs-16">
					{(idBio === infoBio.id) ? infoBio.bioText2 : ''}
				</p>
          	</Box>
        </Modal>
  	)
}

export default Modalinfo;