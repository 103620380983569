import React, { useState, useEffect  } from "react";
import Fancybox from "../Plugin/Fancybox.js";

import './Videos.scss';

const Videos = ({ content, getIdYoutube }) => {
    return(
        <section className="videos">
            <div className="videos__top-detail"></div>
            <div className="container">
                <h2 className="videos__title">{content.title}</h2>
        
                <div className="row">
                    <Fancybox options={{ infinite: false }}>
                        {content.info.map( ( item, key ) => (
                            <div className="col bp466:col-8 bp466:offset-2 bp640:col-3 bp640:offset-0 m-b-60" key={ key }>
                                <div className="card ">
                                    <figure
                                        className="card__picture"
                                        style={{cursor: 'pointer'}}
                                        data-fancybox={`video${item.id}`}
                                        data-src={item.url}
                                    >
                                        <img src={`https://img.youtube.com/vi/${getIdYoutube(item.url)}/hqdefault.jpg`} alt={item.text} />

                                        <img className="card__icon" src="./static/images/icon-polygon1.png" alt="Play" />

                                    </figure>

                                    <div className="card__body">
                                        <h2 className="card__title">{item.text}</h2>
                                    </div>
                                </div>
                            </div>
                        ) )}
                    </Fancybox>
                </div>
            </div>
        </section>
    )
}

export default Videos;