import './App.scss';

import Header from './components/Header';
import Hero from './components/Hero';
import Info from './components/Info';
import Featured from './components/Featured';
import Carousel from './components/Carousel';
import Videos from './components/Videos';
import Footer from './components/Footer';

import content from './content.json';

const { header, videosHero, FeaturedSession, participants, videos, footer } = content.components;

function App() {

  const getIdYoutube = ( url ) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  return (
    <div className="App">
      <Header content={ header } />
      <div className="container">
        <Info content={ videosHero } getIdYoutube={ getIdYoutube } />
        
      </div>
      <img src="./static/images/rosa-t.png" />
      <section className="participantes">
        <h2 className="participantes__title">Conheça os participantes</h2>
        <Carousel content={ participants } />
      </section>
      
      <Videos content={ videos } getIdYoutube={ getIdYoutube } />
      <Footer content={ footer } />
    </div>
  );
}

export default App;