import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

import './CarouselInfo.scss';

const options = {
    autoHeight: true,
    nav: false,
    dots: true,
    margin: 0,
    items: 1,
    responsive: {
        0: {
            items: 1,
            dots: true,
            nav: false,
        },
        768: {
            items: 1,
            dots: true,
            nav: true,
        },

    }
    
}


export default ({ content }) => {
    console.log(content)
    return(
        <section className="carousel-info">
            <OwlCarousel className='owl-theme' { ...options }>
                {content.map( ( item, key ) => (
                    <div className="carousel-info__item">
                        <img src={`./static/images/fotos/${item.thumb}`}/>
                    </div>
                ))}
            </OwlCarousel>
        </section>
    );
}