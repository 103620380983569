import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

import CarouselItem from "./CarouselItem";

import './Carousel.scss';

const options = {
    autoHeight: true,
    nav: true,
    dots: false,
    margin: 20,
    responsive: {
        0: {
            items: 1,
            dots: true, 
            nav: false,
        },
        600: {
            items: 2,
            dots: true,
            nav: false,
        },
        768: {
            items: 3,
            dots: true,
            nav: false,
        },
        1024: {
            items: 4,
            dots: true,
            nav: false,
        },
        1240: {
            items: 4,
            dots: false,
            nav: true,
        },
    }
}

export default ({ content }) => {
    return(
        <section className="carousel container-fluid">
            <OwlCarousel className='owl-theme' {...options}>
                {content.bios.map( ( item, key ) => (
                    <CarouselItem key={ key } info={ item } id={ key } />
                ))}
            </OwlCarousel>
        </section>
    );
}