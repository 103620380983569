import React from "react";
import './Header.scss';

export default ({ content }) => {
    return(
        <header className="header">
                <div className="header__spacer"></div>
                <figure className="header__logo">
                    <img src="./static/images/logo.png" alt={ content.title } />
                </figure>
            
        </header>
    );
}