import React from "react";
import './Footer.scss';

const Footer = ({ content }) => {
    return(
        <footer className="footer">
            <div className="footer__top-detail"></div>
            <div className="footer__fake-bg"></div>
            <div className="container">
                <h2 className="footer__title">CONFIRA AS EDIÇÕES ANTERIORES DO DIÁLOGOS GNT</h2>

                <div className="footer__holder-btns">
                    {content.btns.map( ( item, key ) => (
                        <a key={key} target="_blank" className="btn" href={item.urlBtn}>{item.textBtn}</a>
                    ))}
                </div>
            </div>
        </footer>
    )
}

export default Footer;