import React, { useState } from "react";
import Modalinfo from './Modalinfo';

const CarouselItem = ({ info, id }) => {
    const [showHideModal, setShowHideModal] = useState( false );
    const [getId, setGetId] = useState();

    const handleModal = ( id ) => {
        if( showHideModal ) {
            setShowHideModal( false );
        } else {
            setShowHideModal( true );
        }

        setGetId( id );
    }
    
    return(
        <div className="carousel__item">
            <div className={`card style${info.style}`}>
                <figure className="card__picture">
                    <img src={`./static/images/${info.imageUrl}`} alt={info.name} />
                </figure>

                <div className="card__body">
                    <h2 className="card__title">{info.name}</h2>
                    <a className="card__nickname" target="_blank" href={info.instagramUrl}>{info.subTitle}</a>
                    <p className="card__text">{info.introText}</p>
                    <span href="#" className="read-more" onClick={() => handleModal( id )}>Leia mais &raquo;</span>
                </div>

            </div>

            <Modalinfo show={ showHideModal } setHide={handleModal} infoBio={info} idBio={ getId } />
        </div>
    );
}

export default CarouselItem;